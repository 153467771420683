let config = {
    // appName:'FunStory',
    // // appLogo:require('@/assets/images/logo.png').default,
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1599044136',
    // contactEmail:'funstory.cs@outlook.com',
    // copyright:'FunStory',
    // title:'FunStory',
    // websiteUrl:'funstorys.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1599044136',
    //     'appstore:bundle_id':'swbro.BookManager',
    //     'appstore:store_id':'1599044136'
    // }

    // appName:'Mobooks',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/za/app/id1615497181',
    // contactEmail:'mobooks.cs@outlook.com',
    // copyright:'Mobooks',
    // title:'Mobooks',
    // websiteUrl:'shepherdnovels.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/za/app/id1615497181',
    //     'appstore:bundle_id':'com.mobooks.novel',
    //     'appstore:store_id':'1615497181'
    // }

    // appName:'PandaReads',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/app/id1626427449',
    // contactEmail:'pandareads.cs@outlook.com',
    // copyright:'PandaReads',
    // title:'PandaReads',
    // websiteUrl:'pandareads.ficfunapp.com'

    // appName:'whatsreads',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1629004177',
    // contactEmail:'whatsread.cs@outlook.com',
    // copyright:'whatsreads',
    // title:'whatsreads',
    // websiteUrl:'whatsreads.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1629004177',
    //     'appstore:bundle_id':'com.webnovel.whatsread',
    //     'appstore:store_id':'1629004177'
    // }

    // appName:'Funtel',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1597216091',
    // contactEmail:'funtel.cs@outlook.com',
    // copyright:'Funtel',
    // title:'Funtel',
    // websiteUrl:'funtelnovel.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1597216091',
    //     'appstore:bundle_id':'funfic.novmic.com',
    //     'appstore:store_id':'1597216091'
    // },

    // appName:'Pocket eReader',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1629561758',
    // contactEmail:'pocket_ereader@163.com',
    // copyright:'Pocket eReader',
    // title:'Pocket eReader',
    // websiteUrl:'ereaderlab.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1629561758',
    //     'appstore:bundle_id':'com.pocket.ereader',
    //     'appstore:store_id':'1629561758'
    // },

    // appName:'eReader',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1580895095',
    // contactEmail:'ereader2022@163.com',
    // copyright:'eReader',
    // title:'eReader',
    // websiteUrl:'ereaderlab.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1580895095',
    //     'appstore:bundle_id':'com.kuaidu.reader',
    //     'appstore:store_id':'1580895095'
    // },

    // appName:'Readfic',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1612039188',
    // contactEmail:'readfic.cs@outlook.com',
    // copyright:'Readfic',
    // title:'Readfic',
    // websiteUrl:'readfic.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1612039188',
    //     'appstore:bundle_id':'com.readfic.novel',
    //     'appstore:store_id':'1612039188'
    // },

    // appName:'Sweet',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1630347934',
    // contactEmail:'sweetfic.cs@outlook.com',
    // copyright:'Sweet',
    // title:'Sweet',
    // websiteUrl:'sweetfic.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1630347934',
    //     'appstore:bundle_id':'com.sweet.novel',
    //     'appstore:store_id':'1630347934'
    // },

    // appName:'TOPICKS',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1633000700',
    // contactEmail:'topicks.cs@outlook.com',
    // copyright:'TOPICKS',
    // title:'TOPICKS',
    // websiteUrl:'topicksapp.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1633000700',
    //     'appstore:bundle_id':'com.topicks.novel',
    //     'appstore:store_id':'1633000700'
    // },

    // appName:'PopFic',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1596541746',
    // contactEmail:'popfic2021@163.com',
    // copyright:'PopFic',
    // title:'PopFic',
    // websiteUrl:'thepopfic.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1596541746',
    //     'appstore:bundle_id':'com.cy.popfic.appstore',
    //     'appstore:store_id':'1596541746'
    // }

    // appName:'Pocket eBooks',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1636111360',
    // contactEmail:'pocket_ebooks@163.com',
    // copyright:'Pocket eBooks',
    // title:'Pocket eBooks',
    // websiteUrl:'pocketebooks.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1636111360',
    //     'appstore:bundle_id':'com.pocket.ebooks',
    //     'appstore:store_id':'1636111360'
    // }

    // appName:'Dreams',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1636473927',
    // contactEmail:'dreams.cs@outlook.com',
    // copyright:'Dreams',
    // title:'Dreams',
    // websiteUrl:'dreamsnovel.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1636473927',
    //     'appstore:bundle_id':'com.dreams.novel',
    //     'appstore:store_id'  :'1636473927'
    // },

    // appName:'Good Fiction',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id6443874704',
    // contactEmail:'goodfic.cs@outlook.com',
    // copyright:'Good Fiction',
    // title:'Good Fiction',
    // websiteUrl:'goodfics.com',
    // logoName:'iosgoodfiction',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id6443874704',
    //     'appstore:bundle_id':'com.goodfiction.reader',
    //     'appstore:store_id':'6443874704'
    // },

    // 以下是确权新增的app

    // appName:'Webfiction',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.webfiction.reader',
    // appStoreUrl:null,
    // contactEmail:'webfiction@163.com',
    // copyright:'Webfiction',
    // title:'Webfiction',
    // logoName:'Webfiction',
    // fbid:'1296258547471052',
    // websiteUrl:'webfiction.crazyfic.com'

    // appName:'Free2Read',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.webnovel.free2read',
    // appStoreUrl:null,
    // contactEmail:'free2read.cs@outlook.com',
    // copyright:'Free2Read',
    // title:'Free2Read',
    // logoName:'Free2Read',
    // fbid:'420887282579363',
    // websiteUrl:'free2read.crazyfic.com'

    // appName:'FreeRead',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.free2read.novel',
    // appStoreUrl:null,
    // contactEmail:'freeread.cs@outlook.com',
    // copyright:'FreeRead',
    // title:'FreeRead',
    // logoName:'FreeRead',
    // fbid:'3074504019498792',
    // websiteUrl:'freeread.crazyfic.com'

    // appName:'Webstories',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.novelmanga.lite',
    // appStoreUrl:null,
    // contactEmail:'webstories.cs@outlook.com',
    // copyright:'Webstories',
    // title:'Webstories',
    // logoName:'Webstories',
    // fbid:'4631998320197740',
    // websiteUrl:'webstories.crazyfic.com'

    // appName:'ReadNow',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.readnow.novel',
    // appStoreUrl:null,
    // contactEmail:'readnow2021@163.com',
    // copyright:'ReadNow',
    // title:'ReadNow',
    // logoName:'ReadNow',
    // fbid:'401451608123845',
    // websiteUrl:'readnow.crazyfic.com'

    // appName:'HotNovel',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.wordconnect.crushsaga',
    // appStoreUrl:null,
    // contactEmail:'hotnovel.cs@outlook.com',
    // copyright:'HotNovel',
    // title:'HotNovel',
    // logoName:'HotNovel',
    // fbid:'290108302725127',
    // websiteUrl:'hotnovel.crazyfic.com'

    // appName:'WeRead',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.novel.weread',
    // appStoreUrl:null,
    // contactEmail:'weread.cs@outlook.com',
    // copyright:'WeRead',
    // title:'WeRead',
    // logoName:'WeRead',
    // fbid:'1156600371462407',
    // websiteUrl:'weread.crazyfic.com'

    // appName:'Webnovel',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.webnovel.novel',
    // appStoreUrl:null,
    // contactEmail:'webnovel@yeah.net',
    // copyright:'Webnovel',
    // title:'Webnovel',
    // logoName:'Webnovel',
    // fbid:'1255781194873454',
    // websiteUrl:'webnovel.crazyfic.com'

    // appName:'GoodStory',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.goodstory.reader',
    // appStoreUrl:null,
    // contactEmail:'webgoodstory.cs@outlook.com',
    // copyright:'GoodStory',
    // title:'GoodStory',
    // logoName:'GoodStory',
    // fbid:'297457222456746',
    // websiteUrl:'webgoodstory.crazyfic.com'

    // appName:'Readme',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.novelmanga.readme',
    // appStoreUrl:null,
    // contactEmail:'readme.cs@outlook.com',
    // copyright:'Readme',
    // title:'Readme',
    // logoName:'Readme',
    // fbid:'280504523942762',
    // websiteUrl:'readme.crazyfic.com'

    // appName:'eReader',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.kuaidu.reader',
    // appStoreUrl:null,
    // contactEmail:'ereader2022@163.com',
    // copyright:'eReader',
    // title:'eReader',
    // logoName:'eReader-logo',
    // fbid:'274049691223955',
    // websiteUrl:'ereader.crazyfic.com'

    // appName:'Chapters',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.find.phone.gps.locator.location.tracker',
    // appStoreUrl:null,
    // contactEmail:'chapters.cs@hotmail.com',
    // copyright:'Chapters',
    // title:'Chapters',
    // logoName:'Chapters',
    // fbid:'913995656162063',
    // websiteUrl:'chapters.crazyfic.com'

    // appName:'Romance Stories',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.romancestories.novel',
    // appStoreUrl:null,
    // contactEmail:'romance_stories@163.com',
    // copyright:'Romance Stories',
    // title:'Romance Stories',
    // logoName:'romancestories',
    // fbid:'597817818323570',
    // websiteUrl:'romancestories.crazyfic.com'

    // appName:'FunNovel',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.luckygift.appcash',
    // appStoreUrl:null,
    // contactEmail:'funnovel.cs@outlook.com',
    // copyright:'FunNovel',
    // title:'FunNovel',
    // logoName:'FunNovel',
    // fbid:'2062221060603612',
    // websiteUrl:'funnovel.crazyfic.com'

    // appName:'Top Stories',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.buehistoria.reader',
    // appStoreUrl:null,
    // contactEmail:'topstories.cs@outlook.com',
    // copyright:'Top Stories',
    // title:'Top Stories',
    // logoName:'topstories',
    // fbid:'396475982169932',
    // websiteUrl:'topstories.crazyfic.com'

    // appName:'TopFic',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.webficcion.reader',
    // appStoreUrl:null,
    // contactEmail:'topfic.cs@outlook.com',
    // copyright:'TopFic',
    // title:'TopFic',
    // logoName:'TopFic',
    // fbid:'4611814962173927',
    // websiteUrl:'topfic.crazyfic.com'

    // appName:'SuperNovel',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.luckygift.appgift',
    // appStoreUrl:null,
    // contactEmail:'supernovel.cs@outlook.com',
    // copyright:'SuperNovel',
    // title:'SuperNovel',
    // logoName:'SuperNovel',
    // fbid:'656571278806499',
    // websiteUrl:'supernovel.crazyfic.com'

    // appName:'NovelFox',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.luckygift.giftcard',
    // appStoreUrl:null,
    // contactEmail:'novelfox.cs@outlook.com',
    // copyright:'NovelFox',
    // title:'NovelFox',
    // logoName:'NovelFox',
    // fbid:'1005363113383044',
    // websiteUrl:'novelfox.crazyfic.com'

    // appName:'iNovel',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.inovel.cheetah',
    // appStoreUrl:null,
    // contactEmail:'inovel2021@163.com',
    // copyright:'iNovel',
    // title:'iNovel',
    // logoName:'iNovel',
    // fbid:'3067792036842300',
    // websiteUrl:'inovel.crazyfic.com'

    // appName:'StoryHouse',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.superjoy.toyboxblast',
    // appStoreUrl:null,
    // contactEmail:'storyhouse.cs@outlook.com',
    // copyright:'StoryHouse',
    // title:'StoryHouse',
    // logoName:'StoryHouse',
    // fbid:'630843584819606',
    // websiteUrl:'storyhouse.crazyfic.com'

    // appName:'PopFic',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.popfic.novel',
    // appStoreUrl:null,
    // contactEmail:'popfic2021@163.com',
    // copyright:'PopFic',
    // title:'PopFic',
    // logoName:'PopFic',
    // fbid:'1010599932820944',
    // websiteUrl:'popfic.crazyfic.com'

    // appName:'Read4Fun',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.funplus.flashlight',
    // appStoreUrl:null,
    // contactEmail:'read4fun.cs@outlook.com',
    // copyright:'Read4Fun',
    // title:'Read4Fun',
    // logoName:'Read4Fun',
    // fbid:'431845205297111',
    // websiteUrl:'read4fun.crazyfic.com'

    // appName:'PopNovel',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.luckygift.giftapp',
    // appStoreUrl:null,
    // contactEmail:'popnovel.cs@outlook.com',
    // copyright:'PopNovel',
    // title:'PopNovel',
    // logoName:'PopNovel',
    // fbid:'258537849746152',
    // websiteUrl:'popnovel.crazyfic.com'

    // appName:'FunFic',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.luckygift.cashmaster',
    // appStoreUrl:null,
    // contactEmail:'funfic.cs@outlook.com',
    // copyright:'FunFic',
    // title:'FunFic',
    // logoName:'FunFic',
    // fbid:'304000624917925',
    // websiteUrl:'funfic.crazyfic.com'

    // appName:'PopStories',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.popstories.novel',
    // appStoreUrl:null,
    // contactEmail:'popstories.dev@outlook.com',
    // copyright:'PopStories',
    // title:'PopStories',
    // logoName:'PopStories',
    // fbid:'270826618235663',
    // websiteUrl:'popstories.crazyfic.com'

    // appName:'BravoFiction',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.bravofiction.novel',
    // appStoreUrl:null,
    // contactEmail:'bravofiction.cs@outlook.com',
    // copyright:'BravoFiction',
    // title:'BravoFiction',
    // logoName:'BravoFiction',
    // fbid:'333305262038628',
    // websiteUrl:'bravofiction.crazyfic.com'

    // appName:'NovelNow',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.novelnow.novel',
    // appStoreUrl:null,
    // contactEmail:'novelnow.cs@outlook.com',
    // copyright:'NovelNow',
    // title:'NovelNow',
    // logoName:'NovelNow',
    // fbid:'477004257207403',
    // websiteUrl:'novelnow.crazyfic.com'

    // appName:'NovelWorld',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.novelworld.novel',
    // appStoreUrl:null,
    // contactEmail:'novelworld.cs@outlook.com',
    // copyright:'NovelWorld',
    // title:'NovelWorld',
    // logoName:'NovelWorld',
    // fbid:'791311995598055',
    // websiteUrl:'novelworld.crazyfic.com'

    // appName:'Podfic',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.giftapp.makemoney',
    // appStoreUrl:null,
    // contactEmail:'podfic.cs@outlook.com',
    // copyright:'Podfic',
    // title:'Podfic',
    // logoName:'Podfic',
    // fbid:'1673488889678485',
    // websiteUrl:'podfic.crazyfic.com'

    // appName:'Libfic',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.libfic.novel',
    // appStoreUrl:null,
    // contactEmail:'libfic.dev@outlook.com',
    // copyright:'Libfic',
    // title:'Libfic',
    // logoName:'Libfic',
    // fbid:'463799221984074',
    // websiteUrl:'libfic.libfic.com'

    // appName:'Mobooks',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.mobooks.novel',
    // appStoreUrl:null,
    // contactEmail:'mobooks.cs@outlook.com',
    // copyright:'Mobooks',
    // title:'Mobooks',
    // logoName:'Mobooks',
    // fbid:'2258464414302839',
    // websiteUrl:'mobooks.mobobooks.com'

    // appName:'LoveNovel',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.lovenovel.tiger',
    // appStoreUrl:null,
    // contactEmail:'lovenovel.cs@outlook.com',
    // copyright:'LoveNovel',
    // title:'LoveNovel',
    // logoName:'LoveNovel',
    // fbid:'640771923828767',
    // websiteUrl:'lovenovel.icrazynovel.com'

    // appName:'FICTLF',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.fictlf.novel',
    // appStoreUrl:null,
    // contactEmail:'fictlf.cs@outlook.com',
    // copyright:'FICTLF',
    // title:'FICTLF',
    // logoName:'FICTLF',
    // fbid:'459897592161920',
    // websiteUrl:'fictlf.romancezones.com'

    // appName:'StoryRover',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.story.rover',
    // appStoreUrl:null,
    // contactEmail:'storyrover.cs@outlook.com',
    // copyright:'StoryRover',
    // title:'StoryRover',
    // logoName:'StoryRover',
    // fbid:'1124673794769586',
    // websiteUrl:'storyrover.icrazynovel.com'

    // appName:'Infinity Stories',
    //  googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1639261117',
    // contactEmail:'infinity.stories.cs@outlook.com',
    // copyright:'Infinity Stories',
    // title:'Infinity Stories',
    // websiteUrl:'infinitytale.com',
    // logoName:'infinitytale',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1639261117',
    //     'appstore:bundle_id':'com.infinitytale.novel',
    //     'appstore:store_id':'1639261117'
    // }

    // appName:'Liby',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1608863603',
    // contactEmail:'liby.cs@outlook.com',
    // copyright:'Liby',
    // title:'Liby',
    // logoName:'Liby',
    // websiteUrl:'libynovel.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1608863603',
    //     'appstore:bundle_id':'JKCount.www.com',
    //     'appstore:store_id':'1608863603'
    // },

    // appName:'iRomance',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1643862208',
    // contactEmail:'iromance.cs@outlook.com',
    // copyright:'iRomance',
    // title:'iRomance',
    // websiteUrl:'ireadnovels.com',
    // logoName:'iRomance',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1643862208',
    //     'appstore:bundle_id':'com.iromance.novel',
    //     'appstore:store_id':'1643862208'
    // },

    // appName:'Funfiction',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1643650823',
    // contactEmail:'funfic.cs@outlook.com',
    // copyright:'Funfiction',
    // title:'Funfiction',
    // websiteUrl:'ficfunapp.com',
    // logoName:'FicFun',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1643650823',
    //     'appstore:bundle_id':'com.funfiction.novel',
    //     'appstore:store_id':'1643650823'
    // },

    // appName:'Mynovel',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id6443462567',
    // contactEmail:'mynovel.cs@outlook.com',
    // copyright:'Mynovel',
    // title:'Mynovel',
    // websiteUrl:'menovels.com',
    // logoName:'MyNovel',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id6443462567',
    //     'appstore:bundle_id':'com.mynovel.tiger',
    //     'appstore:store_id':'6443462567'
    // },

    // appName:'iHear',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.ihear.audiobook',
    // appStoreUrl:'https://apps.apple.com/us/app/id6443486279',
    // contactEmail:'ihear.cs@outlook.com',
    // copyright:'iHear',
    // title:'iHear',
    // websiteUrl:'ihearlab.com',
    // logoName:'IHear',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id6443486279',
    //     'appstore:bundle_id':'com.ihear.audiobook',
    //     'appstore:store_id':'6443486279'
    // },

    // appName:'PocketeBooks',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.chatjoy.ch',
    // appStoreUrl:null,
    // contactEmail:'pocketebooks@outlook.com',
    // copyright:'PocketeBooks',
    // title:'PocketeBooks',
    // websiteUrl:'pocketebooks.crazyfic.com',
    // logoName:'PocketeBooks'

    // appName:'SuperFiction',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.super.fiction',
    // appStoreUrl:null,
    // contactEmail:'superfiction.cs@outlook.com',
    // copyright:'SuperFiction',
    // title:'SuperFiction',
    // websiteUrl:'superfiction.crazyfic.com',
    // logoName:'SuperFiction'

    // appName:'GoodFiction',
    // googlePlayUrl:'https://play.google.com/store/apps/details?id=com.good.fiction',
    // appStoreUrl:null,
    // contactEmail:'goodfiction.cs@outlook.com',
    // copyright:'GoodFiction',
    // title:'GoodFiction',
    // websiteUrl:'goodfiction.crazyfic.com',
    // logoName:'GoodFiction'

    // appName: "StoryFM",
    // googlePlayUrl: "",
    // appStoreUrl: "https://apps.apple.com/us/app/id6443824673",
    // contactEmail: "storyfm.cs@outlook.com",
    // copyright: "StoryFM",
    // title: "StoryFM",
    // websiteUrl: "istoryfm.com",
    // logoName: "storyfm",
    // headMeta: {
    //     "appstore:developer_url": "https://apps.apple.com/us/app/id6443824673",
    //     "appstore:bundle_id": "com.storyfm.ios",
    //     "appstore:store_id": "6443824673",
    // },


    appName: "StoryFM",
    googlePlayUrl: "",
    appStoreUrl: "https://apps.apple.com/us/app/id6443824673",
    contactEmail: "storyfm.cs@outlook.com",
    copyright: "StoryFM",
    title: "StoryFM",
    websiteUrl: "podsfm.com",
    logoName: "storyfm",
    headMeta: {
        "appstore:developer_url": "https://apps.apple.com/us/app/id6443824673",
        "appstore:bundle_id": "com.storyfm.ios",
        "appstore:store_id": "6443824673",
    },

    // appName: "GoodStory",
    // googlePlayUrl: "",
    // appStoreUrl: "https://apps.apple.com/us/app/id6443874239",
    // contactEmail: "goodstory.cs@outlook.com",
    // copyright: "GoodStory",
    // title: "GoodStory",
    // websiteUrl: "goodstoryapp.com",
    // logoName: "iosGoodStory",
    // headMeta: {
    //     "appstore:developer_url": "https://apps.apple.com/us/app/id6443874239",
    //     "appstore:bundle_id": "com.goodstory.ereader",
    //     "appstore:store_id": "6443874239",
    // },


};

export default config;
